// CSS reset

/*
Repeating the background mostly makes sense in the <body>. Otherwise, people usually want the image and preferably its center (not the top-right corner)
*/
*:not(body) {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

/*
In applications, there can be lists but HTML lists have bullet points and a bunch of useless styling attributes
*/
ul, ol, menu {
    margin: 0;
    padding: 0;
}

ul, menu {
    list-style: none;
}

li {
    margin: 0;
    padding: 0;
}

/* buttons are clickable */
button{
    cursor: pointer;
}

/* i find default iframe borders ugly */
iframe{
    border: 0;
}

* {
    border-collapse: collapse;
}

/* contain feels like a better default than fill (which stretches the image)*/
img{
    object-fit: contain;
}

// Titles
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}

h1 {
  font-size: 2em;
  line-height: 1.125em;
}

h2 {
  font-size: 1.75em;
  line-height: 1.25em;
}

h3 {
  font-size: 1.5em;
  line-height: 1.5em;
}

h4 {
  font-size: 1.25em;
}

h5 {
  font-size: 1.125em;
}

h6 {
  font-size: 1em;
}

// Global layout
body{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1f1f1f;
}

body > * {
  width: 100%;
}

main{
  padding: 1rem;
  padding-top: 0;
}

// Main title
header .framalibre-title {
  font-size: 3rem;
  line-height: 1.2;

  em {
    font-size: 1.2rem;
    font-weight: normal;
    font-style: italic;
    display: block;
  }
}

header .btn--create {
  color: #fff !important;
  background: transparent;
  border: 1px solid #fff;
  margin-top: 1rem;

  &:hover {
    background-color: #fff;
    color: var(--f-primary) !important;
  }

  @media (min-width: 75rem) {
    margin-top: 0;
  }
}

.text-right--on-large-screen {
  @media (min-width: 75rem) {
    text-align: right;
  }
}
